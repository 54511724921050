import React from 'react';
import UnderConstruction from 'react-under-construction';
import 'react-under-construction/build/css/index.css';

const App = () => (
  <UnderConstruction
    background={{
      image: 'assets/background.jpeg',
      textColor: '#fff',
      overlay: {
        color: '#000',
        opacity: '.5'
      }
    }}
    logo={{
      src: 'assets/logo.png',
      alt: 'Logo'
    }}
    title={{
      text: 'Senkins'
    }}
    description={{
      text: 'Website is under construction. We\'ll be here soon',
      style: {
        maxWidth: '440px',
      }
    }}
    links={[
      {
        url: 'https://www.twitter.com/senkins',
        image: 'assets/twitter.svg',
      },
      {
        url: 'https://www.twitch.tv/senkins',
        image: 'assets/twitch.svg',
      },
    ]}
  />
);

export default App;